import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from './routes'
import auth from '@/modules/auth'

Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior: (to, from) => to.path !== from.path && { x: 0, y: 0 },
})

const DEFAULT_TITLE = 'PBConsig - Servidor'

// Guards
router.beforeEach((to, from, next) => {
  // Sempre que houver um 401 devemos forçar o expire nos dados de autenticação
  // para que o usuário seja forçado a refazer o login
  if (/error\/401/.test(from.path)) auth.expire()

  // const logged = Vue.$keycloak.authenticated
  const logged = auth.isAuthenticated() && auth.hasRoleServidor()
  // const logged = true

  // Redirencionar para definir uma senha.
  if (logged && /login\/password_confirmation/.test(to.path)) {
    auth.logout()
    return next(router.history.pending.fullPath)
  }

  // Redirecionar para Home usuário já logado
  if (logged && /login/.test(to.path)) {
    next('/')
  }

  // Verificar autenticação e redirecionar para login se necessário
  const authRequired = to.matched.some((route) => route.meta && route.meta.auth)

  if (!logged && authRequired) {
    auth.expire()
    return next('/login')
  }

  return next()
})

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  })

  // Aplica classe predefinida para o body
  const styles = to.matched
    .filter((route) => route.meta)
    .filter((route) => route.meta.body)

  document.body.className = styles.length ? styles.pop().meta.body : ''
})

export default router
