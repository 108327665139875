import Vue from 'vue'
import axios from 'axios'
import { getEnv } from '../modules/utils'
import events from './events'
import storage from './storage'
import Config from '@/config'
import auth from '@/modules/auth'

const [id, secret] = [
  getEnv('VUE_APP_CLIENT_ID'),
  getEnv('VUE_APP_CLIENT_SECRET'),
]

const http_login = axios.create({
  baseURL: Config.REALM_PB,
})

/**
 * Singleton para controle de autenticação.
 * @type {Object}
 */
export default {
  get clientCredentials() {
    return window.btoa(`${id}:${secret}`)
  },

  get roles() {
    return storage.get('roles')
  },

  set roles(val) {
    events.$emit('auth:roles', val)
    val ? storage.set('roles', val) : storage.remove('roles')
  },

  get user() {
    return storage.get('user')
  },

  set user(val) {
    events.$emit('auth:user', val)
    val ? storage.set('user', val) : storage.remove('user')
  },

  get expires_in() {
    return storage.get('expires_in')
  },

  set expires_in(val) {
    events.$emit('auth:expires_in', val)
    val ? storage.set('expires_in', val) : storage.remove('expires_in')
  },

  get token() {
    return storage.get('token')
  },

  set refresh_token(val) {
    events.$emit('auth:refresh_token', val)
    val ? storage.set('refresh_token', val) : storage.remove('refresh_token')
  },

  get refresh_token() {
    return storage.get('refresh_token')
  },

  set token(val) {
    events.$emit('auth:token', val)
    val ? storage.set('token', val) : storage.remove('token')
  },

  /**
   * Autentica (registra) o usuário e seu token na aplicação
   */
  login({ token, user, refresh_token, roles, expires_in }) {
    this.user = user
    this.token = token
    this.refresh_token = refresh_token
    this.roles = roles
    this.expires_in = expires_in
    events.$emit('auth:login', {
      token,
      user,
      refresh_token,
      roles,
      expires_in,
    })
  },

  refresh({ token, refresh_token, expires_in }) {
    this.token = token
    this.refresh_token = refresh_token
    this.expires_in = expires_in
    events.$emit('auth:refresh', {
      token,
      refresh_token,
      expires_in,
    })
  },

  /**
   * Encerra a sessão do usuário atualmente autenticado na aplicação
   */
  logout() {
    storage.clear()
    events.$emit('auth:logout')
  },

  expire() {
    storage.clear()
    events.$emit('auth:expire')
  },

  /**
   * Roles dos principais módulos da aplicação
   */

  hasRoleServidor() {
    if (!this.roles) return false
    return this.roles.includes('SERVIDOR')
  },

  hasRoleGestao() {
    if (!this.roles) return false
    return this.roles.includes('GESTÃO')
  },

  hasRoleGestaoAdmin() {
    if (!this.roles) return false
    return this.roles.includes('GESTÃO-ADMIN')
  },

  hasRoleGestaoEntidade() {
    if (!this.roles) return false
    return this.roles.includes('GESTÃO-ENTIDADE')
  },

  hasRoleConsignatariaUser() {
    if (!this.roles) return false
    return this.roles.includes('CONSIGNATÁRIA-USER')
  },

  hasRoleConsignatariaAdmin() {
    if (!this.roles) return false
    return this.roles.includes('CONSIGNATÁRIA-ADMIN')
  },

  hasRoleGestaoFinanceira() {
    if (!this.roles) return false
    return this.roles.includes('GESTÃO-FINANCEIRA')
  },

  /**
   * Roles para acessar botões de ação
   */

  hasRoleAverbar() {
    if (!this.roles) return false
    return this.roles.includes('AVERBAR')
  },
  hasRoleCancelarSuspenderAverbacao() {
    if (!this.roles) return false
    return this.roles.includes('CANCELAR-SUSPENDER CONSIGNAÇÕES')
  },
  hasRoleCancelarSuspenderReservaCartao() {
    if (!this.roles) return false
    return this.roles.includes('CANCELAR-SUSPENDER RESERVA DE CARTÃO')
  },
  hasRoleCancelarSolicitacaoCompraDivida() {
    if (!this.roles) return false
    return this.roles.includes('CANCELAR SOLICITAÇÃO DE COMPRA DE DÍVIDA')
  },
  hasRoleReservaCartao() {
    if (!this.roles) return false
    return this.roles.includes('FAZER RESERVA DE CARTÃO')
  },
  hasRoleCompraDivida() {
    if (!this.roles) return false
    return this.roles.includes('SOLICITAR COMPRA DE DÍVIDA')
  },
  hasRoleConfirmarCompraDivida() {
    if (!this.roles) return false
    return this.roles.includes('CONFIRMAR COMPRA DE DÍVIDA')
  },
  hasRoleRenegociar() {
    if (!this.roles) return false
    return this.roles.includes('RENEGOCIAR CONSIGNAÇÕES')
  },

  /**
   * Mensagem padrão de permissão de acesso.
   */
  msgPermissao() {
    return 'Usuário sem permissão de acesso.'
  },

  /**
   * Verifica se usuário está autenticado
   */
  isAuthenticated() {
    return !!this.token
  },

  async refreshToken() {
    let qs = require('qs')
    let user = qs.stringify({
      client_id: Config.CLIENT_ID,
      refresh_token: this.refresh_token,
      grant_type: 'refresh_token',
    })
    try {
      const { data } = await http_login.post(
        '/protocol/openid-connect/token',
        user,
      )
      this.refresh({
        token: data.access_token,
        refresh_token: data.refresh_token,
        expires_in: data.expires_in,
      })
    } catch (error) {
      console.log('Erro ao atualizar token')
      console.log(error)
    }
  },

  getParsedToken() {
    var base64Url = this.token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join(''),
    )
    return JSON.parse(jsonPayload)
  },

  /**
   *
   * Funções usadas para gerenciar o token através do keycloak-js
   *
   */

  updateToken(redirectUri, callback) {
    if (!Vue.$keycloak.authenticated) {
      // The page is protected and the user is not authenticated. Force a login.
      Vue.$keycloak.login({ redirectUri })
    } else {
      try {
        Vue.$keycloak
          .updateToken(60)
          .then(function (refreshed) {
            if (refreshed || auth.refresh_token === null) {
              auth.token = Vue.$keycloak.token
              auth.refresh_token = Vue.$keycloak.refreshToken
              auth.expires_in = Math.round(
                Vue.$keycloak.tokenParsed.exp +
                  Vue.$keycloak.timeSkew -
                  new Date().getTime() / 1000,
              )
            }
            callback()
          })
          .catch(function () {
            Vue.$keycloak.login({ redirectUri })
          })
      } catch (err) {
        console.log('Failed to refresh token')
      }
    }
  },

  logoutKeycloak() {
    Vue.$keycloak.logout({ redirectUri: window.location.origin })
    storage.clear()
  },
}
